export interface IAppBase {
  appLink: string;
  name: string;
  category: string;
  contactEmail: string;
  free: boolean;
  intendedUsers: string;
  longDescription: string;
  maker: string;
  oneLiner: string;
  pricingInformation: string;
  supportLevel: string;
  techSpec: string;
  useCase: string;
}

export interface IApp extends IAppBase {
  id: number;
  coverImage?: string;
  image1?: string;
  image2?: string;
  image3?: string;
  studioProfileImage?: string;
  related?: IApp[];
}

export interface IAppUpload extends IAppBase {
  coverImage?: File;
  image1?: File;
  image2?: File;
  image3?: File;
  studioProfileImage?: File;
}

export const imageTranslationMap = new Map([
  ['coverImage', 'Cover Image'],
  ['image1', 'Image 1'],
  ['image2', 'Image 2'],
  ['image3', 'Image 3'],
  ['studioProfileImage', 'Studio Profile Image'],
]);
