import React from 'react';
import RankingsEntry from '../Home/Rankings/RankingsEntry';
import { IApp } from '../../types/app';

interface RelatedAppsProps {
    apps: IApp[] | undefined;
}

const RelatedApps = ({ apps }: RelatedAppsProps) => {

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Related Apps</h4>
                    {apps?.map((app, index) => (
                        <RankingsEntry key={index} appInfo={app} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default RelatedApps;