import { useState } from 'react';
import { OrbitRepository } from '../../repository/OrbitRepository';
import { IAppUpload, type IApp } from '../../types/app';
import { AxiosError } from 'axios';

type UseFormScreenHook = (appInfo: IAppUpload) => {
  postApp: () => Promise<void>;
  getApp: (appName: string) => Promise<void>;
  app?: IApp;
};

const useFormScreen: UseFormScreenHook = (appInfo) => {
  const [repository] = useState(() => new OrbitRepository());
  const [app, setApp] = useState<IApp>();

  const postApp = async () => {
    await repository
      .uploadApp(appInfo)
      .then((response) => {
        alert(String(response.status) + ': ' + response.data);
        console.log(response.data);
      })
      .catch(({ response }: AxiosError) => {
        alert(`${response?.status} + ": " + ${response?.data}`);
      });
  };

  const getApp = async (appName: string) => {
    await repository
      .getAppByName(appName)
      .then((response) => {
        setApp(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { postApp, getApp, app };
};

export default useFormScreen;
