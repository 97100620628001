import { type AxiosResponse } from 'axios';
import { IAppUpload, type IApp } from '../types/app';
import request from '../utils/fetch';
import { type IOrbitRepository } from './IOrbitRepository';
import { getUrl } from './api';
import { IFilter } from '../types/filters';
import { ICategory } from '../types/category';

const baseUrl = getUrl();

export class OrbitRepository implements IOrbitRepository {
  async getRankings(selectedFilters: IFilter): Promise<AxiosResponse<IApp[]>> {
    const { category, free } = selectedFilters;
    return await request.get(`${baseUrl}/apps/rankings?category=${category}&free=${free}`);
  }

  async getAppsFiltered(search: string): Promise<AxiosResponse<IApp[]>> {
    return await request.get(`${baseUrl}/apps/filtered/${search}`);
  }

  async getApp(id: number): Promise<AxiosResponse<IApp>> {
    return await request.get(`${baseUrl}/apps/${id}`);
  }

  async getAppByName(name: string): Promise<AxiosResponse<IApp>> {
    return await request.get(`${baseUrl}/apps?name=${name}`);
  }

  async uploadApp(appData: IAppUpload): Promise<AxiosResponse<string>> {
    return await request.post({
      url: `${baseUrl}/apps`,
      data: appData,
      opts: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 20000,
      },
      shallow: true,
    });
  }

  async getAllCategories(): Promise<AxiosResponse<ICategory[]>> {
    return await request.get(`${baseUrl}/apps/categories`);
  }
}
