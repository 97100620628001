import React from 'react';
import { Header } from './_shared';
import { Outlet } from 'react-router';

const App = () => (
  <div className="text-center">
    <Header />
    <Outlet />
  </div>
);

export default App;
