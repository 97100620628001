import React from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessage, Loading } from '../_shared';
import AppInfo from './AppInfo';
import DemoUses from './DemoUses';
import ProfileSpotlight from './ProfileSpotlight';
import useAppProfile from './useAppProfile';
import RelatedApps from './RelatedApps';

function AppProfile() {
  const { appId } = useParams() as any as { appId: number };

  const { app, loading } = useAppProfile(appId);

  return (
    <div className="mt-2">
      {loading ? (
        <Loading />
      ) : app ? (
        <>
          <ProfileSpotlight app={app} />
          <AppInfo app={app} />
          <DemoUses app={app} />
          <RelatedApps apps={app.related} />
        </>
      ) : (
        <ErrorMessage message={'App Not Found'} />
      )}
    </div>
  );
}

export default AppProfile;
