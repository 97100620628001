import React from 'react';
import { type IApp } from '../../types/app';
import UseExample from './UseExample';

interface DemoUsesProps {
  app: IApp;
}
const DemoUses = ({ app }: DemoUsesProps) => (
  <div className="container-fluid">
    <div className="row">
      <UseExample img={app.image1} text="Example Use 1"></UseExample>
      <UseExample img={app.image2} text="Example Use 2"></UseExample>
      <UseExample img={app.image3} text="Example Use 3"></UseExample>
    </div>
  </div>
);

export default DemoUses;
