import React from 'react';

interface Props {
  img?: string;
  text: string;
}
const UseExample = ({ img, text }: Props) => (
  <div className="col-sm-3 m-auto">
    <img
      src={img}
      alt=""
      style={{ width: '100%', maxHeight: '400px', height: 'auto' }}
    />
    <p className="caption m-2">{text}</p>
  </div>
);

export default UseExample;
