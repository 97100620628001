import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RankingsEntry from '../../Home/Rankings/RankingsEntry';
import useHeader from './useHeader';
import Modal from '../Modal/Modal';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const Header = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { apps, getApps, error } = useHeader();

  const debouncedFetch = React.useRef(
    debounce(async (filter) => {
      await getApps(filter);
      if (filter) {
        setShowModal(true);
      }
    }, 400)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    void debouncedFetch(e.target.value);
  };

  const toggleBurger = () => {
    setOpen(!open);
  };

  useEffect(() => {
    debouncedFetch.cancel();
  }, [debouncedFetch]);

  return (
    <>
      <nav
        className="navbar navbar-expand-sm bg-primary mb-0"
        style={{ minHeight: '80px' }}
        data-bs-theme="dark"
      >
        <div className="container-fluid justify-content-left">
          <Link className="navbar-brand mx-2" to="/">
            <p className="text-light h1 m-1">ORBIT</p>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleBurger}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              open && 'show d-flex flex-column align-items-center'
            }`}
          >
            <ul className="navbar-nav">
              <Link className="nav-link" to="/about">
                About
              </Link>
              <Link className="nav-link" to="/submit-app">
                Submit
              </Link>
            </ul>
            <div className="input-group mx-2" style={{ maxWidth: '200px' }}>
              <input
                type="text"
                className="form-control rounded text-dark"
                placeholder="Search"
                value={search}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </nav>
      <Modal
        open={showModal}
        setOpen={setShowModal}
        content={
          apps.length > 0 ? (
            <>
              {apps.map((app, index) => {
                return (
                  <RankingsEntry
                    appInfo={app}
                    key={index}
                    onClick={() => {
                      setShowModal(false);
                    }}
                  />
                );
              })}
            </>
          ) : (
            <ErrorMessage message={error} />
          )
        }
        onClose={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default Header;
