import React from 'react';
import { type IApp } from '../../types/app';
import { Link } from 'react-router-dom';

interface ProfileSpotlightProps {
  app: IApp;
}

const ProfileSpotlight = ({ app }: ProfileSpotlightProps) => (
  <div className="m-3">
    <div className="card bg-lightshadow-sm rounded container-fluid">
      <div className="row justify-content-around align-items-center m-2">
        <div className="col-md-4 m-2 my-5">
          <img src={app.coverImage} alt="" width="50%" className="rounded" />
        </div>
        <div className="col-xl-6 col-lg-7 bg-dark m-1 p-2 rounded">
          <p className="h1 text-light">
            {app.name} - {app.oneLiner}
          </p>
          <div className="d-flex flex-row justify-content-between">
            <p className="m-2 h5 text-light">By {app.maker}</p>
            <Link to={`${app.appLink}`} target="_blank">
              <button
                type="button"
                className="btn btn-outline-dark btn-light rounded"
              >
                Visit App
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProfileSpotlight;
