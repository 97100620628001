import React from 'react';

interface Props {
  title: string;
  error?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}
const FormEntry = ({ error, onChange, value, title }: Props) => {
  return (
    <div className="form-group text-left">
      <label className="form-label m-2 text-left">{title}</label>
      <input
        type="text"
        className="form-control"
        onChange={onChange}
        placeholder=""
        value={value}
      />
      <small className="form-text m-2 text-danger">{error}</small>
    </div>
  );
};

export default FormEntry;
