import React from 'react';

const Loading = () => {
  return (
    <div className="progress m-3">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        style={{ width: '100%' }}
      ></div>
    </div>
  );
};
export default Loading;
