import React from 'react';
import useCategoryDropdown from './useCategoryDropdown';

interface Props {
    title?: string;
    error?: string;
    value?: string;
    selectClassName: string;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    defaultSelected: string;
}
const CategoryDropdown = ({ title, error, value, selectClassName, onChange, defaultSelected }: Props) => {

    const { categories } = useCategoryDropdown();

    return (
        <div className="form-group text-left">
            {title && (
                <label htmlFor="category" className="form-label m-2 text-left">
                    {title}
                </label>
            )}
            <select
                className={selectClassName}
                id="category"
                value={value}
                onChange={onChange}
            >
                <option>{defaultSelected}</option>
                {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                        {category.name}
                    </option>
                ))}
            </select>
            {error && <small className="form-text m-2 text-danger">{error}</small>}
        </div>
    );
};

export default CategoryDropdown;