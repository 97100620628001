import React, { StrictMode } from 'react';
import App from './components/App';
import AppProfile from './components/AppProfile/AppProfile';
import FormScreen from './components/AppSubmission/FormScreen';
import AboutPage from './components/About/About';
import reportWebVitals from './utils/reportWebVitals';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ErrorMessage } from './components/_shared';
import Home from './components/Home/Home';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement: <ErrorMessage message="Page Not Found " />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/app/:appId',
        element: <AppProfile />,
      },
      {
        path: 'submit-app',
        element: <FormScreen />,
      },
      {
        path: 'about',
        element: <AboutPage />,
      },
    ],
  },
]);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);

reportWebVitals(console.log)
  .then(() => {})
  .catch((error) => {
    console.error(error);
  });
