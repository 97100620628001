import React, { useState } from 'react';
import { type IApp } from '../../types/app';

interface AppInfoProps {
  app: IApp;
}
const AppInfo = ({ app }: AppInfoProps) => {
  const [active, setActive] = useState('Built To');
  const tabs = new Map([
    ['Built To', app.useCase],
    ['Tech Specs', app.techSpec],
    ['Pricing', app.pricingInformation],
    ['Built For', app.intendedUsers],
    ['Support', app.contactEmail],
  ]);
  return (
    <div className="m-2">
      <ul className="nav nav-tabs">
        {['Built To', 'Tech Specs', 'Pricing', 'Built For', 'Support'].map(
          (tab) => (
            <li className="nav-item" role="presentation" key={tab}>
              <a
                className={`nav-link ${active === tab ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setActive(tab);
                }}
              >
                {tab}
              </a>
            </li>
          )
        )}
      </ul>
      <div className="m-2" style={{ minHeight: '150px', textAlign: 'left' }}>
        <div className="tab-pane fade show active">
          <ul>
            {tabs
              .get(active)
              ?.valueOf()
              .split('.')
              .map((item) => item && <li key={item}>{item}</li>)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppInfo;
