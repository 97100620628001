import React from 'react';
import { Link } from 'react-router-dom';
import { type IApp } from '../../../types/app';

interface RankingEntryProps {
  appInfo: IApp;
  rank?: number;
  onClick?: any;
}

const RankingsEntry = ({ appInfo, rank, onClick }: RankingEntryProps) =>
  appInfo && (
    <Link
      to={`/app/${appInfo.id}`}
      style={{ textDecoration: 'none' }}
      onClick={onClick}
      reloadDocument
    >
      <div className="RankingsEntry-Container">
        <h5 className="m-1">{rank}</h5>
        <div className="d-flex flex-row">
          <img
            src={appInfo.studioProfileImage}
            alt=""
            width="70px"
            className="rounded"
          />
          <div className="d-flex flex-column RankingsEntry-Info">
            <h5 className="m-0 flex-1">{appInfo.name}</h5>
            <h6 className="m-0 flex-2">{appInfo.oneLiner}</h6>
            <small className="m-0 flex-1">{appInfo.category}</small>
          </div>
        </div>
      </div>
    </Link>
  );

export default RankingsEntry;
