import React from 'react';
import RankingsEntry from './RankingsEntry';
import { type IApp } from '../../../types/app';

import './rankings.scss';

interface RankingsProps {
  rankings: IApp[];
}

const Rankings = ({ rankings }: RankingsProps) =>
  rankings && (
    <div className="container-lg">
      <div className="d-flex-resize flex-row m-2 justify-content-center">
        <div className="d-flex flex-column flex-1 border-0">
          {rankings.slice(0, 5).map((entry, index) => (
            <RankingsEntry key={index} rank={index + 1} appInfo={entry} />
          ))}
        </div>
        <div className="d-flex flex-column flex-1 border-0">
          {rankings.slice(5, 10).map((entry, index) => (
            <RankingsEntry key={index} rank={index + 6} appInfo={entry} />
          ))}
        </div>
      </div>
    </div>
  );

export default Rankings;
