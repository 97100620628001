import React from 'react';
import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import { IApp, IAppUpload } from '../types/app';
import { IErrors } from '../types/error';

type ValidationFunction = (value: string, options?: any) => boolean;

const validateInput = (
  value: string,
  key: keyof IApp,
  validationFns: ValidationFunction[],
  validationOptions: any[],
  errorMessages: string[],
  setErrors: React.Dispatch<React.SetStateAction<IErrors>>
) => {
  for (let i = 0; i < validationFns.length; i++) {
    if (!validationFns[i](value, validationOptions[i])) {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: errorMessages[i] }));
      return;
    }
  }

  setErrors((prevErrors) => ({ ...prevErrors, [key]: '' }));
};

export const validateLink = (
  value: string,
  setErrors: React.Dispatch<React.SetStateAction<IErrors>>
) => {
  validateInput(
    value,
    'appLink',
    [isURL],
    [undefined],
    ['Please enter a valid URL'],
    setErrors
  );
};

export const validateEmail = (
  value: string,
  setErrors: React.Dispatch<React.SetStateAction<IErrors>>
) => {
  validateInput(
    value,
    'contactEmail',
    [isEmail],
    [undefined],
    ['Please enter a valid email'],
    setErrors
  );
};

export const validateName = (
  value: string,
  setErrors: React.Dispatch<React.SetStateAction<IErrors>>
) => {
  validateInput(
    value,
    'name',
    [isAlphanumeric, isLength],
    [undefined, { min: 2, max: 30 }],
    [
      'Please enter an alphanumeric value',
      'Name must be between 2 and 30 characters',
    ],
    setErrors
  );
};

export const validateString = (
  key: keyof IApp,
  readableKey: string,
  value: string,
  minLength: number,
  maxLength: number,
  setErrors: React.Dispatch<React.SetStateAction<IErrors>>
) => {
  validateInput(
    value,
    key,
    [isLength],
    [{ min: minLength, max: maxLength }],
    [
      `${readableKey} must be between ${minLength} and ${maxLength} characters.`,
    ],
    setErrors
  );
};

export const checkErrorsAndAlert = (
  appInfo: IAppUpload,
  errors: IErrors,
  postApp: () => Promise<void>
): void => {
  const errorValues = Object.values(errors);
  const appInfoValues = Object.values(appInfo);

  const hasEmptyStrings = appInfoValues.some((value) => value === '');
  const hasUndefinedFields = appInfoValues.some((value) => value === undefined);

  let errorMessage = '';

  if (errorValues.some((value) => value !== '')) {
    const populatedErrors = errorValues.filter((value) => value !== '');
    errorMessage += populatedErrors.join('\n') + '\n';
  }

  if (hasEmptyStrings) {
    errorMessage += 'Please fill all fields for submission.\n';
  }

  if (hasUndefinedFields) {
    errorMessage += 'Please populate all images for submission.\n';
  }

  if (errorMessage === '') {
    console.log('submit');
    void postApp();
  } else {
    alert(errorMessage);
  }
};
