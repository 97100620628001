import React from 'react';

const About = () => {
  return (
    <div className="d-flex flex-column bg-secondary m-2">
      <div className="p-3">
        <p>
          Welcome to ORBIT, the marketplace for AI apps. Our platform connects
          developers and users, providing a hub for discovering, showcasing, and
          exploring cutting-edge applications powered by artificial
          intelligence.
        </p>
        <p>
          Whether you{"'"}re a developer looking to share your innovative
          creations or a user in search of intelligent solutions, ORBIT is your
          gateway to the world of AI-driven technology.
        </p>
        <p>
          Browse our extensive collection of apps, unleash the potential of AI,
          and join us in shaping the future of innovation.
        </p>
        <p>
          For any inquiries or assistance, please feel free to contact us at{' '}
          <a href="mailto:maclark25@gmail.com">maclark25@gmail.com</a>.
        </p>
      </div>
    </div>
  );
};

export default About;
