import { useEffect, useState } from 'react';
import { OrbitRepository } from '../../repository/OrbitRepository';
import { type IApp } from '../../types/app';
import { IFilter } from '../../types/filters';

type UseHomeHook = (selectedFilters: IFilter) => {
  rankings: IApp[];
  spotlight?: IApp;
  loading: boolean;
  loadingSpotlight: boolean;
};

const useHome: UseHomeHook = (selectedFilters) => {
  const [rankings, setRankings] = useState<IApp[]>([]);
  const [spotlightId, setSpotlightId] = useState<number>();
  const [spotlight, setSpotlight] = useState<IApp>();
  const [loadingSpotlight, setLoadingSpotlight] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [repository] = useState(() => new OrbitRepository());

  useEffect(() => {
    console.log('use effect called ', selectedFilters)
    void repository
      .getRankings(selectedFilters)
      .then(({ data }) => {
        setRankings(data);
        setSpotlightId(data.at(0)?.id);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedFilters]);

  useEffect(() => {
    if (spotlightId) {
      void repository
        .getApp(spotlightId)
        .then(({ data }) => {
          setSpotlight(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingSpotlight(false);
        });
    }
  }, [spotlightId]);

  return { rankings, spotlight, loading, loadingSpotlight };
};

export default useHome;
