import React, { ChangeEvent, MouseEventHandler } from 'react';
import {
  validateEmail,
  validateLink,
  validateName,
  validateString,
} from '../../utils/validator';
import FormEntry from './FormEntry';
import { IAppUpload } from '../../types/app';
import { IErrors } from '../../types/error';
import CategoryDropdown from '../_shared/Categories/CategoryDropdown';

interface Props {
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: keyof IAppUpload
  ) => void;
  handleFree: MouseEventHandler<HTMLInputElement>;
  errors: IErrors;
  setErrors: any;
  appInfo: IAppUpload;
}

const MainForm = ({
  errors,
  setErrors,
  appInfo,
  handleChange,
  handleFree,
}: Props) => {
  return (
    <div className="row">
      <div className="column col-md-6">
        <FormEntry
          error={errors.name}
          value={appInfo.name}
          title="App Name"
          onChange={(e) => {
            const value = e.target.value;
            handleChange(e, 'name');
            validateName(value, setErrors);
          }}
        />
        <FormEntry
          error={errors.oneLiner}
          value={appInfo.oneLiner}
          title="One Liner"
          onChange={(e) => {
            handleChange(e, 'oneLiner');
            validateString(
              'oneLiner',
              'One liner',
              e.target.value,
              3,
              40,
              setErrors
            );
          }}
        />
        <FormEntry
          error={errors.longDescription}
          value={appInfo.longDescription}
          title="Long Description"
          onChange={(e) => {
            handleChange(e, 'longDescription');
            validateString(
              'longDescription',
              'Long description',
              e.target.value,
              3,
              500,
              setErrors
            );
          }}
        />
        <FormEntry
          error={errors.useCase}
          value={appInfo.useCase}
          title="Use Case"
          onChange={(e) => {
            handleChange(e, 'useCase');
            validateString(
              'useCase',
              'Use cases',
              e.target.value,
              3,
              150,
              setErrors
            );
          }}
        />
        <FormEntry
          error={errors.supportLevel}
          value={appInfo.supportLevel}
          title="Support Level"
          onChange={(e) => {
            handleChange(e, 'supportLevel');
            validateString(
              'supportLevel',
              'Support level',
              e.target.value,
              3,
              150,
              setErrors
            );
          }}
        />
        <FormEntry
          error={errors.intendedUsers}
          value={appInfo.intendedUsers}
          title="Intended Users"
          onChange={(e) => {
            handleChange(e, 'intendedUsers');
            validateString(
              'intendedUsers',
              'Intended user information',
              e.target.value,
              3,
              150,
              setErrors
            );
          }}
        />
        <div className="form-entry-checkbox">
          <fieldset className="form-group">
            <p className="my-1">Free:</p>
            <div className="d-flex flex-row justify-content-center">
              <div
                style={{ width: '50px' }}
                className="d-flex justify-content-between flex-row"
              >
                <input
                  className="form-check-input ml-2"
                  type="radio"
                  onChange={() => { }}
                  checked={appInfo.free}
                  onClick={handleFree}
                  id="optionsRadios1"
                  value="yes"
                />
                <label className="form-check-label">Yes</label>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <div
                style={{ width: '50px' }}
                className="d-flex justify-content-between flex-row"
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={!appInfo.free}
                  onChange={() => { }}
                  onClick={handleFree}
                  id="optionsRadios2"
                  value="no"
                />
                <label className="form-check-label">No</label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="column col-md-6">
        <FormEntry
          error={errors.appLink}
          value={appInfo.appLink}
          title="App Link"
          onChange={(e) => {
            const value = e.target.value;
            handleChange(e, 'appLink');
            validateLink(value, setErrors);
          }}
        />
        <FormEntry
          error={errors.contactEmail}
          value={appInfo.contactEmail}
          title="Contact Email"
          onChange={(e) => {
            const value = e.target.value;
            handleChange(e, 'contactEmail');
            validateEmail(value, setErrors);
          }}
        />
        <FormEntry
          error={errors.maker}
          value={appInfo.maker}
          title="Maker"
          onChange={(e) => {
            handleChange(e, 'maker');
            validateString('maker', 'Maker', e.target.value, 3, 50, setErrors);
          }}
        />
        <FormEntry
          error={errors.pricingInformation}
          value={appInfo.pricingInformation}
          title="Pricing Information"
          onChange={(e) => {
            handleChange(e, 'pricingInformation');
            validateString(
              'pricingInformation',
              'Pricing information',
              e.target.value,
              3,
              150,
              setErrors
            );
          }}
        />
        <FormEntry
          error={errors.techSpec}
          value={appInfo.techSpec}
          title="Tech Spec"
          onChange={(e) => {
            handleChange(e, 'techSpec');
            validateString(
              'techSpec',
              'Tech spec',
              e.target.value,
              3,
              150,
              setErrors
            );
          }}
        />
        <CategoryDropdown selectClassName="form-select"
          error={errors.category}
          value={appInfo.category}
          title="Category"
          onChange={(e) => {
            handleChange(e, 'category');
          }}
          defaultSelected='Select Category' />
      </div>
    </div>
  );
};

export default MainForm;
