import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import camelize from 'camelize-ts';
import snakify from 'snakify-ts';

export const get = async <T, D = any, R = AxiosResponse<T>>(
  url: string,
  opts?: AxiosRequestConfig<D>
): Promise<R> =>
  await (camelize(await axios.get<T, D>(url, opts)) as unknown as Promise<R>);

interface PostOpts<D = any> {
  url: string;
  data: D;
  opts?: AxiosRequestConfig<D>;
  shallow?: boolean;
}

export const post = async <T, D = any, R = AxiosResponse<T>>({
  url,
  data,
  opts,
  shallow,
}: PostOpts<D>): Promise<R> =>
  await (camelize(
    await axios.post<T, D>(url, snakify(data, shallow), opts)
  ) as unknown as Promise<R>);

export default { get, post };
