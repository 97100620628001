import React, { ChangeEvent, MouseEventHandler, useState } from 'react';
import { IAppUpload } from '../../types/app';
import { IErrors } from '../../types/error';
import { checkErrorsAndAlert } from '../../utils/validator';
import ImageDrop from './ImageDrop';
import MainForm from './MainForm';
import './formScreen.scss';
import useFormScreen from './useFormScreen';
import FormEntry from './FormEntry';

const FormScreen = () => {
  const [appInfo, setAppInfo] = useState<IAppUpload>({
    appLink: '',
    name: '',
    category: '',
    contactEmail: '',
    coverImage: undefined,
    free: true,
    image1: undefined,
    image2: undefined,
    image3: undefined,
    intendedUsers: '',
    longDescription: '',
    maker: '',
    oneLiner: '',
    pricingInformation: '',
    studioProfileImage: undefined,
    supportLevel: '',
    techSpec: '',
    useCase: '',
  });
  const [searchedAppName, setSearchedAppName] = useState<string>('');

  const [errors, setErrors] = useState<IErrors>({
    appLink: '',
    contactEmail: '',
    name: '',
    oneLiner: '',
    category: '',
    longDescription: '',
    useCase: '',
    supportLevel: '',
    maker: '',
    pricingInformation: '',
    techSpec: '',
    intendedUsers: '',
  });

  const { postApp, getApp, app } = useFormScreen(appInfo);

  const handleSearchAppNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchedAppName(e.target.value);
  };

  const handleFree: MouseEventHandler<HTMLInputElement> = (e) => {
    setAppInfo({ ...appInfo, free: e.currentTarget.value === 'yes' });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: keyof IAppUpload
  ) => {
    e.preventDefault();
    setAppInfo({ ...appInfo, [key]: e.target.value });
  };

  const handleImageChange = (image: File, key: keyof IAppUpload) => {
    setAppInfo({ ...appInfo, [key]: image });
  };

  return (
    <div className="">
      <form className="">
        <h3 className="h2 m-2">App Details</h3>
        <div className="container-fluid">
          <div className="row">
            <ImageDrop
              handleImageChange={handleImageChange}
              image={appInfo.coverImage}
              imageKey="coverImage"
            />
            <ImageDrop
              handleImageChange={handleImageChange}
              image={appInfo.image1}
              imageKey="image1"
            />
            <ImageDrop
              handleImageChange={handleImageChange}
              image={appInfo.image2}
              imageKey="image2"
            />
            <ImageDrop
              handleImageChange={handleImageChange}
              image={appInfo.image3}
              imageKey="image3"
            />
            <ImageDrop
              handleImageChange={handleImageChange}
              image={appInfo.studioProfileImage}
              imageKey="studioProfileImage"
            />
          </div>
        </div>

        <div className="container-fluid">
          <MainForm
            errors={errors}
            setErrors={setErrors}
            appInfo={appInfo}
            handleChange={handleChange}
            handleFree={handleFree}
          />
        </div>
        <button
          className="btn btn-outline-dark btn-light rounded m-3"
          onClick={(e) => {
            e.preventDefault();
            checkErrorsAndAlert(appInfo, errors, postApp);
          }}
        >
          Upload
        </button>
      </form>

      <div className="border-top border-3">
        <p className="h3 pt-3">Search App</p>
        <div className="w-25 m-auto">
          <FormEntry
            title="App Name"
            value={searchedAppName}
            onChange={handleSearchAppNameChange}
          />
        </div>
        <button
          className="btn btn-outline-dark btn-light rounded m-3"
          onClick={async (e) => {
            await getApp(searchedAppName);
          }}
        >
          Search
        </button>
        {app && (
          <div className="d-flex flex-column">
            <h4>{app.name}</h4>
            <p>{app.oneLiner}</p>
            <p>Category: {app.category}</p>
            <p>Use Case: {app.useCase}</p>
            <p>{app.free ? 'Free' : 'Paid'}</p>
            <p>Pricing Information: {app.pricingInformation}</p>
            <p>Tech Spec: {app.techSpec}</p>
            <p>Support Level: {app.supportLevel}</p>
            <p>Contact Email: {app.contactEmail}</p>
            <p>Maker: {app.maker}</p>
            <p>Intended Users: {app.intendedUsers}</p>
            <div className="d-flex flex-row justify-content-around flex-wrap">
              <img src={app.coverImage} alt="CoverImage" className="m-auto" />
              <img src={app.image1} alt="UseCase1" className="m-auto" />
              <img src={app.image2} alt="UseCase2" className="m-auto" />
              <img src={app.image3} alt="UseCase3" className="m-auto" />
              <img
                src={app.studioProfileImage}
                alt="StudioProfileImage"
                className="m-auto"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormScreen;
