import React, { useState, ChangeEvent, useEffect } from 'react';
import { IFilter } from '../../types/filters';
import CategoryDropdown from '../_shared/Categories/CategoryDropdown';
import { Loading } from '../_shared';

interface FiltersProps {
  onFilterSelection: (filters: IFilter) => void;
}

const Filters: React.FC<FiltersProps> = ({ onFilterSelection }) => {
  const [selectedFilters, setSelectedFilters] = useState<IFilter>({
    free: false,
    category: "All Categories"
  });

  const handleFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      category: selectedValue,
    }))
  };

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.id;
    let result = selectedValue === "btnradio2"

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      free: result,
    }));
  };

  useEffect(() => {
    onFilterSelection(selectedFilters);
  }, [selectedFilters]);

  return (
    <div className="d-flex justify-content-center align-items-center gap-3">
      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onChange={handleToggleChange} defaultChecked />
        <label className="btn btn-outline-primary" htmlFor="btnradio1">
          All
        </label>
        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" onChange={handleToggleChange}/>
        <label className="btn btn-outline-primary" htmlFor="btnradio2">
          Free Only
        </label>
      </div>
      <CategoryDropdown selectClassName="btn btn-primary" onChange={handleFilterChange} defaultSelected='All Categories'/>
    </div>
    
  );
};

export default Filters;

