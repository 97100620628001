import React from 'react';
import Dropzone from 'react-dropzone';
import { IAppUpload, imageTranslationMap } from '../../types/app';
import { transformImage, validateImage } from '../../utils/validateImage';

interface Props {
  handleImageChange: (image: File, key: keyof IAppUpload) => void;
  image?: File;
  imageKey: keyof IAppUpload;
}

const ImageDrop = ({ handleImageChange, image, imageKey }: Props) => (
  <Dropzone
    onDrop={async (e: File[]) => {
      const image = new Image();
      const url = URL.createObjectURL(e[0]);

      image.onload = async () => {
        transformImage(image);
        const valid = validateImage(image);

        if (valid) {
          void fetch(image.src)
            .then(async (res) => await res.arrayBuffer())
            .then((buffer) => new File([buffer], e[0].name))
            .then((file) => {
              handleImageChange(file, imageKey);
            });
        } else {
          alert(
            'Image dimensions are not sufficient, please attach a higher quality image'
          );
        }
      };

      image.src = url;
    }}
    accept={{ 'image/*': ['.png', '.pdf', '.jpg'] }}
    multiple={false}
  >
    {({ getRootProps, getInputProps }) => (
      <div
        className=" btn btn-outline-dark dropzone d-flex flex-column bg-light rounded p-0 m-auto my-2"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!image ? (
          <p className="h6 m-auto text-wrap w-75">
            {imageTranslationMap.get(imageKey)}:
          </p>
        ) : (
          <img
            className="rounded"
            height="100%"
            width="100%"
            alt=""
            src={URL.createObjectURL(image)}
          />
        )}
      </div>
    )}
  </Dropzone>
);

export default ImageDrop;
