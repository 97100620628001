import { useState } from 'react';
import { IApp } from '../../../types/app';
import { OrbitRepository } from '../../../repository/OrbitRepository';

const useHeader = () => {
  const [apps, setApps] = useState<IApp[]>([]);
  const [error, setError] = useState('');
  const [repository] = useState(() => new OrbitRepository());

  const getApps = async (search: string) => {
    await repository
      .getAppsFiltered(search)
      .then(({ data }) => {
        setApps(data);
      })
      .catch((_err) => {
        setError('No Apps found');
        setApps([]);
      });
  };

  return { apps, getApps, error };
};

export default useHeader;
