import { useEffect, useState } from 'react';
import { ICategory } from '../../../types/category';
import { OrbitRepository } from '../../../repository/OrbitRepository';

const useCategoryDropdown = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [repository] = useState(() => new OrbitRepository());

  useEffect(() => {
    void repository
      .getAllCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((error) => {
        console.log(error);
      })
  }, []);

  return { categories };
};

export default useCategoryDropdown;