import React, { Dispatch, SetStateAction, useEffect } from 'react';

interface ModalProps {
  content: React.JSX.Element;
  onClose?: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const Modal = ({ content, onClose, open, setOpen }: ModalProps) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => {
      window.removeEventListener('keydown', close);
    };
  }, []);

  return (
    open && (
      <div className="modal show d-block" id="modal" tabIndex={-1}>
        <div className="modal-dialog h-25" role="document">
          <div className="modal-content h-100">
            <div className="modal-body overflow-scroll">{content}</div>
            {onClose && (
              <div className="modal-footer d-flex" style={{ height: '60px' }}>
                <button
                  type="button"
                  className="btn btn-secondary m-2 p-0"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
