import React, { useState } from 'react';
import ProfileSpotlight from '../AppProfile/ProfileSpotlight';
import Rankings from './Rankings/Rankings';
import useHome from './useHome';
import { Loading } from '../_shared';
import { IFilter } from '../../types/filters';
import Filters from './Filters';

const Home = () => {
  const [selectedFilters, setSelectedFilters] = useState<IFilter>({
    free: false,
    category: '',
  });
  const { rankings, spotlight, loading, loadingSpotlight } = useHome(selectedFilters);

  const handleFilterSelection = (filters: IFilter) => {
    setSelectedFilters(filters);
  };

  return (
    <>
      {loadingSpotlight || !spotlight ? (
        <Loading />
      ) : (
        <ProfileSpotlight app={spotlight} />
      )}
      <Filters onFilterSelection={handleFilterSelection} />
      {loading ? <Loading /> : <Rankings rankings={rankings} />}
    </>
  );
};

export default Home;
