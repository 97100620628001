import { useEffect, useState } from 'react';
import { type IApp } from '../../types/app';
import { OrbitRepository } from '../../repository/OrbitRepository';
import { AxiosError } from 'axios';

type UseAppProfileHook = (id?: number) => {
  app?: IApp;
  loading: boolean;
  error: string;
};

const useAppProfile: UseAppProfileHook = (id) => {
  const [app, setApp] = useState<IApp>();
  const [repository] = useState(() => new OrbitRepository());
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (id) {
      void repository
        .getApp(id)
        .then(({ data }) => {
          setApp(data);
        })
        .catch((error: AxiosError) => {
          setError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return { app, loading, error };
};

export default useAppProfile;
